/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import "./src/styles/global.css";
import { AuthProvider } from './src/context/AuthProvider';
import store from './src/redux/store';

export const wrapRootElement = ({ element }) => (
  <ReduxProvider store={store}>
    <AuthProvider>{element}</AuthProvider>
  </ReduxProvider>
);

