// src/redux/actions.js
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';
export const CLEAR_LOGIN_EMAIL = 'CLEAR_LOGIN_EMAIL';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});
