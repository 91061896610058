import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDWiMMty_wI_vya1GCRM2Du8lJXnNmwFYk",
    authDomain: "westworth-gate.firebaseapp.com",
    projectId: "westworth-gate",
    storageBucket: "westworth-gate.firebasestorage.app",
    messagingSenderId: "935187755827",
    appId: "1:935187755827:web:f39907cdc64dec0bc88446",
    measurementId: "G-GE6YVQCJCN"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
