// src/context/AuthProvider.js
import React, { useEffect, useContext, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { setUser, clearUser } from '../redux/actions';
import { auth } from '../firebase/firebase';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        dispatch(setUser({
          user: {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
          },
          token,
        }));
        localStorage.setItem('authToken', token); // Optional for persistence
      } else {
        dispatch(clearUser());
        localStorage.removeItem('authToken');
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
