exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dev-404-page-jsx": () => import("./../../../src/pages/dev-404-page.jsx" /* webpackChunkName: "component---src-pages-dev-404-page-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-vacancies-jsx": () => import("./../../../src/pages/vacancies.jsx" /* webpackChunkName: "component---src-pages-vacancies-jsx" */)
}

