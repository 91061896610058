// src/redux/reducer.js
import { SET_USER, CLEAR_USER } from './actions';

const initialState = {
  user: null,
  authToken: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.token,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
        authToken: null,
      };
    default:
      return state;
  }
};

export default userReducer;
